import { Badge } from 'react-daisyui';

type LanguageBadgeProps = {
  code: string;
};

function LanguageBadge(props: LanguageBadgeProps) {
  const { code } = props;
  return (
    <Badge
      size="sm"
      outline
      className="pl-1.5! pr-1! pt-px font-mono text-[.6rem]! font-bold tracking-widest opacity-50"
    >
      {code}
    </Badge>
  );
}

export const LANGUAGES = [
  {
    code: 'ja',
    name: '日本語',
    icon: <LanguageBadge code="JA" />,
  },
  {
    code: 'en',
    name: 'English',
    icon: <LanguageBadge code="EN" />,
  },
  {
    code: 'zh-Hans',
    name: '简体中文',
    icon: <LanguageBadge code="ZH" />,
  },
  {
    code: 'zh-Hant',
    name: '正體中文',
    icon: <LanguageBadge code="ZH" />,
  },
  {
    code: 'ko',
    name: '한국어',
    icon: <LanguageBadge code="KO" />,
  },
  {
    code: 'vi',
    name: 'Tiếng Việt',
    icon: <LanguageBadge code="VI" />,
  },
] as const;

// eslint-disable-next-line react-refresh/only-export-components
export const supportedLanguageCodes = LANGUAGES.map((lang) => lang.code);

export type LanguageCode = (typeof LANGUAGES)[number]['code'];
